import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { colors, fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";
import { TOGGLE_SHOPPING_CART_SUBSCRIPTION } from "../../../graphql/mutations";
import { GET_MEMBER_SHOPPING_CART, GET_MEMBER_SUBSCRIPTION } from "../../../graphql/queries";
import { isLoggedIn } from "../../../helpers/auth";
import urlPatterns from "../../../urls";

import ButtonMutation from "../../atoms/ButtonMutation";

import iconClose from "../../../assets/images/icons/back.svg";

const StyledBoxTrayCartHeader = styled.div`
  background-color: ${(props) => (props.isBox ? colors.warning : colors.blue)};
  padding: 25px 40px;
  position: relative;
  text-align: center;
  flex-shrink: 0;
  ${breakpoints.smDown} {
    padding: 15px;
    display: flex;
    justify-content: center;
  }
`;

const StyledBoxTrayCartHeaderCloseButton = styled.button`
  display: inline-flex;
  background: url(${iconClose}) no-repeat 50% 50% / 14px;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20px;
  left: 10px;
  padding: 0;
  ${breakpoints.smUp} {
    display: none;
  }
  ${breakpoints.smDown} {
    top: 50%;
    transform: translateY(-50%);
  }
`;

const OptionsButtons = styled.div`
  display: flex;
  align-items: center;

  ${breakpoints.smDown} {
    justify-content: center;
    height: 100%;
    width: 80%;
    padding-right: 25px;
  }
`;

const OptionsButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height 100%;

  ${breakpoints.xsDown} {
    height: inherit;
  }
`;

const optionButtonStyle = css`
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  font-family: ${(props) => (props.selected ? fonts.fontInterstateUltraBlack : fonts.fontConceptRegular)};
  text-transform: ${(props) => props.selected && "uppercase"};
  position: relative;
  height: 30px;
  border-radius: 25px;
  margin-right: -25px;
  &:hover {
    color: ${(props) => (props.selected ? colors.white : colors.black)};
  }
  color: ${(props) => (props.selected ? colors.white : colors.black)};
  background-color: ${(props) => (props.selected ? colors.black : colors.white)};
  z-index: ${(props) => (props.selected ? 1 : 0)};

  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoints.smUp} {
    max-width: 200px;
  }

  width: 50%;
  ${breakpoints.smDown} {
    width: 60%;
    flex-direction: column;
  }
`;

const OptionButtonMutation = styled(ButtonMutation)`
  ${optionButtonStyle}
`;

const OptionButtonLink = styled.button`
  ${optionButtonStyle}
`;

const BoxTrayCartHeader = (props) => {
  const {
    handleChange,
    isBox,
    isCart,
    memberId,
    history,
    onBoxCartToggleLoading,
    isLoading,
    refetchMemberLayoutInfo,
  } = props;
  let isCartBeingLoaded;
  let isBoxBeingLoaded;

  const callBackBeforeToggleToBoxMutation = () => {
    isBoxBeingLoaded = true;
    isCartBeingLoaded = false;
    onBoxCartToggleLoading(isBoxBeingLoaded, isCartBeingLoaded);
  };

  const callBackBeforeToggleToCartMutation = () => {
    isBoxBeingLoaded = false;
    isCartBeingLoaded = true;
    onBoxCartToggleLoading(isBoxBeingLoaded, isCartBeingLoaded);
  };

  const handleResponseAfterToggleMutation = async () => {
    // This is required to make sure that toggle doesn't flicker between one-off cart and subscription box
    // during the toggling
    await refetchMemberLayoutInfo();
    isBoxBeingLoaded = false;
    isCartBeingLoaded = false;
    onBoxCartToggleLoading(isBoxBeingLoaded, isCartBeingLoaded);
  };

  return (
    <StyledBoxTrayCartHeader isBox={isBox}>
      <StyledBoxTrayCartHeaderCloseButton onClick={handleChange} />
      {isLoggedIn() ? (
        <OptionsButtons>
          <OptionButtonMutation
            input={{ memberId }}
            mutationProp={TOGGLE_SHOPPING_CART_SUBSCRIPTION}
            reFetchQueriesProp={[{ query: GET_MEMBER_SHOPPING_CART }, { query: GET_MEMBER_SUBSCRIPTION }]}
            label=""
            submittingLabel=""
            selected={isBox}
            disabled={isLoading || isBox}
            callbackBeforeMutation={callBackBeforeToggleToBoxMutation}
            handleResponse={handleResponseAfterToggleMutation}
            awaitRefetchQueries
          >
            <OptionsButtonWrapper>
              <p>Monthly box</p>
            </OptionsButtonWrapper>
          </OptionButtonMutation>
          <OptionButtonMutation
            input={{ memberId }}
            mutationProp={TOGGLE_SHOPPING_CART_SUBSCRIPTION}
            reFetchQueriesProp={[{ query: GET_MEMBER_SHOPPING_CART }, { query: GET_MEMBER_SUBSCRIPTION }]}
            label=""
            submittingLabel=""
            selected={isCart}
            disabled={isLoading || isCart}
            callbackBeforeMutation={callBackBeforeToggleToCartMutation}
            handleResponse={handleResponseAfterToggleMutation}
            awaitRefetchQueries
          >
            <OptionsButtonWrapper>
              <p>One-off order</p>
            </OptionsButtonWrapper>
          </OptionButtonMutation>
        </OptionsButtons>
      ) : (
        <OptionsButtons>
          <OptionButtonLink onClick={() => history.push(urlPatterns.LOGIN)} selected={isBox} disabled={isBox}>
            <OptionsButtonWrapper>Monthly box</OptionsButtonWrapper>
          </OptionButtonLink>
          <OptionButtonLink onClick={() => {}} selected={isCart} disabled={isCart}>
            <OptionsButtonWrapper>One-off order</OptionsButtonWrapper>
          </OptionButtonLink>
        </OptionsButtons>
      )}
    </StyledBoxTrayCartHeader>
  );
};

BoxTrayCartHeader.propTypes = {
  handleChange: PropTypes.func,
  onBoxCartToggleLoading: PropTypes.func,
  refetchMemberLayoutInfo: PropTypes.func.isRequired,
  isBox: PropTypes.bool.isRequired,
  isCart: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  memberId: PropTypes.number,
  history: PropTypes.shape({}).isRequired,
};

BoxTrayCartHeader.defaultProps = {
  handleChange: () => {},
  onBoxCartToggleLoading: () => {},
  memberId: null,
};

export default withRouter(BoxTrayCartHeader);
