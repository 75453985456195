import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";

import { isLoggedIn } from "../../../helpers/auth";
import { header } from "../../../styles/variables";
import { useDeviceSize } from "../../../helpers/hooks";

import AnnouncementBar from "../../atoms/AnnouncementBar";
import HeaderLogo from "../../atoms/HeaderLogo";
import MobileAccordions from "../../atoms/MobileHeaderNavbar";
import HeaderLoggedIn from "../../molecules/HeaderLoggedIn";
import HeaderSignInBox from "../../molecules/HeaderSignInBox";
import { BOX_CART_TOGGLE_V2, NAVIGATION_MEMBER } from "../../../helpers/constants";
import DesktopHeaderNavbar from "../../atoms/DesktopHeaderNavbar";
import DesktopNavbarDropdowns from "../../molecules/DesktopNavbarDropdowns";
import { checkIfUrlIsInUrlGroup } from "../../../helpers/urls.helper";
import { NAVBAR_URL_PATTERNS_WITHOUT_BOX_SHADOW } from "../../../helpers/urls.constants";
import { MemberLayoutInfo } from "../../../types/__generated__/MemberLayoutInfo";
import {
  OpacityLayer,
  StyledMobileHeader,
  Topbar,
  StyledDesktopHeader,
  StyledMobileHeaderWrapper,
} from "./style";
import { HeaderContext } from "./context";

interface HeaderProps {
  hasUnreadRecentNotifications: boolean;
  refetchMemberLayoutInfoData: Function;
  firstName: string;
  isHeaderTransparent: boolean;
  showAnnouncementBar: boolean;
  showBoxAndCartTray: boolean;
  setHeightOfAnnouncement: Function;
  onClickSignIn: Function;
  setHeaderDisplay: Function;
  isHeaderShown: boolean;
  hasTabBarUnderHeader: boolean;
  isBoxCartTrayOpened: boolean;
  showHeaderBarFromChild: boolean;
  memberLayoutInfoData: MemberLayoutInfo | undefined;
}

const Header = ({
  showBoxAndCartTray = true,
  isHeaderTransparent = false,
  showAnnouncementBar = false,
  isBoxCartTrayOpened = false,
  showHeaderBarFromChild = false,
  hasTabBarUnderHeader = false,
  setHeaderDisplay,
  setHeightOfAnnouncement,
  onClickSignIn,
  isHeaderShown,
  hasUnreadRecentNotifications,
  refetchMemberLayoutInfoData,
  firstName,
  memberLayoutInfoData,
}: HeaderProps) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isChangingPage, setIsChangingPage] = useState<boolean>(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [previousScrollY, setPreviousScrollY] = useState<number>(0);
  const [scrollY, setScrollY] = useState<number>(0);
  const [isAnnouncementBarShown, setIsAnnouncementBarShown] = useState<boolean>(false);
  const [activeDesktopMenuItem, setActiveDesktopMenuItem] = useState<string>("");
  const [announcementYLocation, setAnnouncementYLocation] = useState({
    mdUp: "100%",
    mdDown: header.mobileHeight,
  });
  const isBoxCartToggleV2Enabled = useFlag(BOX_CART_TOGGLE_V2);

  const value = useMemo(
    () => ({
      setIsMobileMenuOpen,
      setActiveDesktopMenuItem,
    }),
    [setIsMobileMenuOpen, setActiveDesktopMenuItem]
  );

  const { isSmallOrMedium } = useDeviceSize();
  const isDesktop = !isSmallOrMedium;
  const location = useLocation();

  useEffect(() => {
    setIsMobileMenuOpen(false);
    setActiveDesktopMenuItem("");
    setIsScrolling(false);
    setHeaderDisplay(true);
    setIsChangingPage(true);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    setIsAnnouncementBarShown(showAnnouncementBar);
    setAnnouncementYLocation({
      mdUp: hasTabBarUnderHeader ? header.withTabBarDesktopHeight : "100%",
      mdDown: hasTabBarUnderHeader ? header.withTabBarMobileHeight : header.mobileHeight,
    });
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasTabBarUnderHeader]);

  useEffect(() => {
    const isCurrentlyScrolling = window.scrollY > 30;

    // Opening the notificationsTray counts as scrolling, we want to mitigate that.
    const isScrollingDown = !isNotificationsOpen && scrollY > previousScrollY;
    setIsScrolling(isCurrentlyScrolling);
    if (isHeaderShown === isScrollingDown) {
      // CASE: If scrolling down, hides header if shown
      // and, if scrolling up, shows header if hidden.
      isCurrentlyScrolling && setHeaderDisplay(!isScrollingDown);
    }
    setPreviousScrollY(scrollY);
    isDesktop && setActiveDesktopMenuItem("");
  }, [scrollY]);

  const handleSignInClick = () => {
    isMobileMenuOpen && setIsMobileMenuOpen(false);
    onClickSignIn(true);
  };

  const handleOutsideClick = () => {
    isMobileMenuOpen && setIsMobileMenuOpen(false);
    !!activeDesktopMenuItem && setActiveDesktopMenuItem("");
  };

  const toggleMemberDropdown = () => {
    const isMemberDropdownActive = !!(activeDesktopMenuItem === NAVIGATION_MEMBER);
    isMemberDropdownActive && setActiveDesktopMenuItem("");
    !isMemberDropdownActive && setActiveDesktopMenuItem(NAVIGATION_MEMBER);
  };

  /**
   * When Header Bar is force shown after BoxCartTab is opened in <Layout>'s setHeaderDisplay(true),
   * then, "isHeaderShown" and "showHeaderBarFromChild" will have different values.
   * For pages with TabBar component (i.e. <GiftTabs>) and <AnnouncementBar> component,
   * ...this function fixes the blank space issue between the header and announcement bar.
   * */
  const checkAnnouncementDisplayUnderTabBar = (): boolean => {
    if (hasTabBarUnderHeader) {
      return isHeaderShown === showHeaderBarFromChild;
    }
    return true;
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setIsChangingPage(false);
  };

  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    setActiveDesktopMenuItem("");
  };

  const isMemberLoggedIn = !!isLoggedIn();
  const history = useHistory();

  const shouldHideNavbarBoxShadow = useMemo(
    () => checkIfUrlIsInUrlGroup(history?.location?.pathname, NAVBAR_URL_PATTERNS_WITHOUT_BOX_SHADOW),
    [history?.location?.pathname]
  );

  const shouldShowAnnouncement =
    isScrolling &&
    showAnnouncementBar &&
    isAnnouncementBarShown &&
    !isMobileMenuOpen &&
    !isBoxCartTrayOpened &&
    checkAnnouncementDisplayUnderTabBar();

  const renderHeaderLoggedInBoxOrSignInBox = (): ReactElement =>
    isMemberLoggedIn ? (
      <HeaderLoggedIn
        hasUnreadRecentNotifications={hasUnreadRecentNotifications}
        refetchMemberLayoutInfoData={() => refetchMemberLayoutInfoData}
        firstName={firstName}
        isMemberDropDownOpen={activeDesktopMenuItem === NAVIGATION_MEMBER}
        isAnyDropDownOpen={!!activeDesktopMenuItem}
        isMobileMenuOpen={isMobileMenuOpen}
        toggleMobileMenu={() => toggleMobileMenu()}
        toggleNotifications={toggleNotifications}
        onMouseClickMyAccounts={toggleMemberDropdown}
        points={memberLayoutInfoData?.me?.pointsBalance}
      />
    ) : (
      <HeaderSignInBox
        onClickSignIn={onClickSignIn}
        isMobileMenuOpen={isMobileMenuOpen}
        toggleMobileMenu={() => toggleMobileMenu()}
      />
    );

  const renderAnnouncementBar = (): ReactElement => (
    <AnnouncementBar
      locateTop={announcementYLocation}
      closeAnnouncementHandler={() => setIsAnnouncementBarShown(false)}
      isLoggedIn={isMemberLoggedIn}
      showBoxAndCartTray={showBoxAndCartTray}
      setHeightOfAnnouncement={setHeightOfAnnouncement}
      hasTabBarUnderHeader={hasTabBarUnderHeader}
      isBoxCartToggleV2Enabled={isBoxCartToggleV2Enabled}
    />
  );

  const renderDesktopNavbar = (): ReactElement => (
    <StyledDesktopHeader
      isHeaderTransparent={isHeaderTransparent}
      isScrolling={isScrolling}
      isScrollingDown={!isHeaderShown}
      shouldHideNavbarBoxShadow={shouldHideNavbarBoxShadow}
      shouldIncreaseZIndex={!!activeDesktopMenuItem || !!isNotificationsOpen}
    >
      <Topbar>
        <HeaderLogo
          isHeaderTransparent={isHeaderTransparent}
          isScrolling={isScrolling}
          isDesktopNavOpen={!!activeDesktopMenuItem}
          onLogoClick={() => setActiveDesktopMenuItem("")}
        />
        <DesktopHeaderNavbar
          setActiveMenuItem={setActiveDesktopMenuItem}
          activeMenuItem={activeDesktopMenuItem}
        />
        {renderHeaderLoggedInBoxOrSignInBox()}
      </Topbar>
      {shouldShowAnnouncement && renderAnnouncementBar()}
      <DesktopNavbarDropdowns
        onCrossClicked={() => setActiveDesktopMenuItem("")}
        activeDropdown={activeDesktopMenuItem}
        memberLayoutInfoData={memberLayoutInfoData}
      />
    </StyledDesktopHeader>
  );

  const renderMobileNavbar = (): ReactElement => (
    <StyledMobileHeaderWrapper
      isMobileMenuOpen={isMobileMenuOpen}
      isScrollingDown={!isHeaderShown}
      isNotificationsOpen={isNotificationsOpen}
    >
      <StyledMobileHeader
        isMobileMenuOpen={isMobileMenuOpen}
        isNotificationsOpen={isNotificationsOpen}
        isChangingPage={isChangingPage}
      >
        <Topbar>
          <HeaderLogo
            isHeaderTransparent={isHeaderTransparent}
            isScrolling={isScrolling}
            isDesktopNavOpen={false}
          />
          {renderHeaderLoggedInBoxOrSignInBox()}
        </Topbar>
        <MobileAccordions
          onClickSignIn={handleSignInClick}
          firstName={firstName}
          isMobileMenuOpen={isMobileMenuOpen}
        />
        {shouldShowAnnouncement && renderAnnouncementBar()}
      </StyledMobileHeader>
    </StyledMobileHeaderWrapper>
  );

  return (
    <>
      <OpacityLayer
        onClick={() => handleOutsideClick()}
        isActive={isMobileMenuOpen || !!activeDesktopMenuItem}
      />
      <HeaderContext.Provider value={value}>
        {isDesktop ? renderDesktopNavbar() : renderMobileNavbar()}
      </HeaderContext.Provider>
    </>
  );
};

export default Header;
