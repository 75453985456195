import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors, fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";
import iconClose from "../../../assets/images/icons/arrow-right-straight.svg";
import { getCashAmountLabel } from "../../../helpers/i18n";

const StyledBoxTrayPriceToggleButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => (props.yellow ? colors.warning : colors.blue)};
  width: 80px;
  height: 80px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0px;
  left: 0;
  ${breakpoints.smUp} {
    transform: translateX(-100%);
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${iconClose}) no-repeat 50% 50% / 20px;
    opacity: ${(props) => (props.isOpened ? "1" : "0")};
    ${breakpoints.smDown} {
      background-size: 14px;
    }
  }
  ${breakpoints.smDown} {
    width: 100%;
    height: auto;
    background-size: 14px;
    justify-content: space-between;
    flex-direction: row;
    padding: 8.5px 15px;
    position: relative;
    display: ${(props) => props.isOpened && "none"};
  }
`;

// Support for iOS Safari <= 10
const BoxTrayPriceToggleButtonTitleWrapper = styled.div`
  ${breakpoints.smDown} {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
  }
  ${breakpoints.smUp} {
    margin-bottom: 2px;
  }
`;

const BoxTrayPriceToggleButtonTitle = styled.p`
  color: ${colors.black};
  font-size: 12px;
  text-transform: uppercase;
  font-family: ${fonts.fontBauMedium};
  margin-bottom: 2px;
  opacity: ${(props) => props.isOpened && "0"};
`;

const BoxTrayPriceToggleButtonPriceTitle = styled.p`
  color: ${colors.black};
  font-size: 25px;
  font-family: ${fonts.fontInterstateBlackCompressed};
  opacity: ${(props) => props.isOpened && "0"};
  ${breakpoints.smDown} {
    font-size: 20px;
    margin-left: auto;
  }
`;

const BoxTrayPriceToggleButton = (props) => {
  const { title, price, handleChange, isOpened, yellow } = props;

  return (
    <StyledBoxTrayPriceToggleButton onClick={handleChange} isOpened={isOpened} yellow={yellow}>
      <BoxTrayPriceToggleButtonTitleWrapper>
        <BoxTrayPriceToggleButtonTitle isOpened={isOpened}>{title}</BoxTrayPriceToggleButtonTitle>
      </BoxTrayPriceToggleButtonTitleWrapper>
      <BoxTrayPriceToggleButtonPriceTitle isOpened={isOpened}>
        {getCashAmountLabel(price)}
      </BoxTrayPriceToggleButtonPriceTitle>
    </StyledBoxTrayPriceToggleButton>
  );
};

BoxTrayPriceToggleButton.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  isOpened: PropTypes.bool,
  handleChange: PropTypes.func,
  yellow: PropTypes.bool,
};

BoxTrayPriceToggleButton.defaultProps = {
  title: "",
  price: 0,
  isOpened: false,
  yellow: false,
  handleChange: () => {},
};

export default BoxTrayPriceToggleButton;
