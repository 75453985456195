import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";

// @ts-ignore: @types/react-scrolllock doesn't exist yet
import ScrollLock, { TouchScrollable } from "react-scrolllock";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

import { useQuery, useMutation } from "@apollo/client";
import { header } from "../../../styles/variables";
import {
  GET_AUTH,
  GET_LOCAL_SHOPPING_CART,
  GET_MEMBER_LAYOUT_INFO,
  GET_MEMBER_SHOPPING_CART,
  GET_MEMBER_SUBSCRIPTION,
} from "../../../graphql/queries";
import { TOGGLE_SHOPPING_CART_SUBSCRIPTION } from "../../../graphql/mutations";
import {
  BOX_CART_TOGGLE_V2,
  FETCH_POLICY_CACHE_ONLY,
  I18N_NAMESPACES,
  PRODUCT_TYPE_IDS,
  UNLEASH_BEER_SUBSCRIPTION,
} from "../../../helpers/constants";
import { useDeviceSize } from "../../../helpers/hooks";
import { extractNumberFromString, checkIfScrollLockInSideTrayShouldBeActive } from "../../../helpers/tools";

import BoxTrayCartHeader from "../../molecules/BoxTrayCartHeader";
import BoxTrayHolder from "../../molecules/BoxTrayHolder";
import BoxTrayPriceToggleButton from "../../atoms/BoxTrayPriceToggleButton";
import URL_PATTERNS from "../../../urls";
import {
  LocalShoppingCart,
  LocalShoppingCart_localShoppingCart_items as LocalShoppingCartItem,
} from "../../../types/__generated__/LocalShoppingCart";
import { ToggleShoppingCartSubscription } from "../../../types/__generated__/ToggleShoppingCartSubscription";
import { getAuth } from "../../../types/__generated__/getAuth";
import { isLoggedIn } from "../../../helpers/auth";
import StyledLoading from "../../atoms/Loading/StyledLoading";
import BoxCartTogglesController from "../../molecules/BoxCartTogglesController";
import BoxAndCartTrayMobileBottomSection from "../../atoms/BoxAndCartTrayMobileBottomSection";
import { MemberLayoutInfo } from "../../../types/__generated__/MemberLayoutInfo";
import { HolderInner, SmDownBackdrop, StyledBoxTray } from "./styles";

const BoxTrayCartContent = React.lazy(() => import("../../molecules/BoxTrayCartContent"));

interface BoxAndCartTrayProps {
  heightOfAnnouncement: number;
  shouldOpenBoxAndCartTray?: boolean;
  setShouldOpenBoxAndCartTray: (shouldOpenBoxAndCartTray: boolean) => void;
  isHeaderShown: boolean;
  setHeaderDisplay: (HeaderDisplay: boolean) => void;
  setBoxCartTrayDisplay: (BoxCartTrayDisplay: boolean) => void;
  showHeaderBarFromChild: boolean;
  showWelcomePackWarning?: boolean;
  toggleWelcomePackWarning: () => void;
  refetchMemberLayoutInfo: () => void;
  shouldWinesBeAddedToSubscription: boolean;
  totalPriceOnToggleButton: number;
  memberLayoutInfoData: MemberLayoutInfo | undefined;
}

const BoxAndCartTray = ({
  heightOfAnnouncement,
  shouldOpenBoxAndCartTray = false,
  setShouldOpenBoxAndCartTray,
  isHeaderShown,
  setHeaderDisplay,
  setBoxCartTrayDisplay,
  showHeaderBarFromChild,
  showWelcomePackWarning = false,
  toggleWelcomePackWarning,
  refetchMemberLayoutInfo,
  shouldWinesBeAddedToSubscription,
  totalPriceOnToggleButton,
  memberLayoutInfoData,
}: BoxAndCartTrayProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.MY_BOX]);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isCartBeingLoaded, setIsCartBeingLoaded] = useState<boolean>(false);
  const [isBoxBeingLoaded, setIsBoxBeingLoaded] = useState<boolean>(false);
  const [isMonthlyBoxSelected, setIsMonthlyBoxSelected] = useState<boolean>(shouldWinesBeAddedToSubscription);
  const isBoxCartToggleV2Enabled = useFlag(BOX_CART_TOGGLE_V2);
  const isBeerSubscriptionEnabled = useFlag(UNLEASH_BEER_SUBSCRIPTION);
  const isUserLoggedIn = isLoggedIn();

  useEffect(() => {
    setIsMonthlyBoxSelected(shouldWinesBeAddedToSubscription);
  }, [shouldWinesBeAddedToSubscription]);

  let isCart = !isMonthlyBoxSelected;
  let isBox = isMonthlyBoxSelected;
  const { isSmall } = useDeviceSize();
  const history = useHistory();

  // In order to not to use optimisticResponse, which is slow,
  // we track the loading state to keep the toggle quick and responsive
  if (isCartBeingLoaded || isBoxBeingLoaded) {
    isCart = isCartBeingLoaded;
    isBox = isBoxBeingLoaded;
  }
  const { data: authData } = useQuery<getAuth>(GET_AUTH, {
    fetchPolicy: FETCH_POLICY_CACHE_ONLY,
  });

  const { data: localShoppingCartData } = useQuery<LocalShoppingCart>(GET_LOCAL_SHOPPING_CART, {
    fetchPolicy: FETCH_POLICY_CACHE_ONLY,
  });

  const { me } = memberLayoutInfoData || {};
  const boxAmountBottles = me?.subscription?.amountBottles ?? 0;
  const boxAmountPresents = me?.subscription?.amountPresents ?? 0;
  let cartAmountBottles = 0;
  let cartAmountPresents = 0;
  if (isUserLoggedIn) {
    cartAmountBottles = me?.shoppingCart?.amountBottles ?? 0;
    cartAmountPresents = me?.shoppingCart?.amountPresents ?? 0;
  } else {
    cartAmountBottles = (localShoppingCartData?.localShoppingCart?.items ?? []).reduce(
      (totalBottles, item) =>
        totalBottles +
        (item?.product?.productType?.id === PRODUCT_TYPE_IDS.DB_ID_PRODUCT_TYPE_WINE
          ? item?.quantity ?? 0
          : 0),
      0
    );
    cartAmountPresents = (localShoppingCartData?.localShoppingCart?.items ?? []).reduce(
      (totalPresents, item) =>
        totalPresents +
        (item?.product?.productType?.id &&
        [PRODUCT_TYPE_IDS.DB_ID_PRODUCT_TYPE_SPECIAL_PACK, PRODUCT_TYPE_IDS.DB_ID_PRODUCT_TYPE_GIFT].includes(
          item.product.productType.id
        )
          ? item?.quantity ?? 0
          : 0),
      0
    );
  }

  useEffect(() => {
    if (shouldOpenBoxAndCartTray) {
      setTimeout(() => {
        handleChange();
      }, 1000);
    }
  }, [shouldOpenBoxAndCartTray]);

  useEffect(() => {
    isSmall && setIsOpened(false);
  }, [history?.length]);

  const handleChange = () => {
    setHeaderDisplay(true);
    setBoxCartTrayDisplay(!isOpened);
    setIsOpened(!isOpened);
    setShouldOpenBoxAndCartTray(false);
  };

  const handleBoxCartToggleLoading = (boxLoading: boolean, cartLoading: boolean) => {
    setIsBoxBeingLoaded(boxLoading);
    setIsCartBeingLoaded(cartLoading);
  };

  const [toggleShoppingCartSubscription] = useMutation<ToggleShoppingCartSubscription>(
    TOGGLE_SHOPPING_CART_SUBSCRIPTION,
    {
      refetchQueries: [
        { query: GET_MEMBER_SHOPPING_CART },
        { query: GET_MEMBER_SUBSCRIPTION },
        { query: GET_MEMBER_LAYOUT_INFO },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleBoxCartToggle = async (isMonthlyBox: boolean) => {
    const isClickingOnActiveToggle = isMonthlyBox === isBox;
    const shouldCloseTray = isOpened && isClickingOnActiveToggle;
    const isLoading = isBoxBeingLoaded || isCartBeingLoaded;

    if (shouldCloseTray) {
      handleCloseTray();
      return;
    }

    if (!isOpened) {
      if (isClickingOnActiveToggle) {
        handleChange();
      } else {
        setTimeout(() => handleChange(), 300);
      }
    }

    if (!isClickingOnActiveToggle) {
      if (isMonthlyBox && !memberId) {
        history.push(URL_PATTERNS.LOGIN);
      } else if (!isLoading) {
        setIsMonthlyBoxSelected(isMonthlyBox);
        handleBoxCartToggleLoading(isMonthlyBox, !isMonthlyBox);
        await toggleShoppingCartSubscription({
          variables: {
            input: {
              memberId,
            },
          },
        });
        handleBoxCartToggleLoading(false, false);
      }
    }
  };

  /**
   * Calculates the top position of box and cart tray and height offset,
   * for smUp and mdUp screen sizes.
   *
   * @returns {Object}
   * */
  const getTopAndHeightInfo = () => {
    const isHeaderDisplayed = isHeaderShown || showHeaderBarFromChild;

    const smUpTop = isHeaderDisplayed
      ? `${extractNumberFromString(header.mobileHeight) + heightOfAnnouncement}px`
      : `${heightOfAnnouncement}px`;

    const mdUpTop = isHeaderDisplayed
      ? `${extractNumberFromString(header.desktopHeight) + heightOfAnnouncement}px`
      : `${heightOfAnnouncement}px`;

    const smUpHeightOffset = isHeaderDisplayed
      ? `${extractNumberFromString(header.mobileHeight) + heightOfAnnouncement}px`
      : `${heightOfAnnouncement}px`;

    const mdUpHeightOffset = isHeaderDisplayed
      ? `${extractNumberFromString(header.desktopHeight) + heightOfAnnouncement}px`
      : `${heightOfAnnouncement}px`;

    return {
      smUpTop,
      mdUpTop,
      smUpHeightOffset,
      mdUpHeightOffset,
    };
  };

  /**
   * This function closes the box/cart tray.
   * */
  const handleCloseTray = () => {
    // Keeps the tray open if the showWelcomePackWarning was visible.
    const closeTray = showWelcomePackWarning;

    setHeaderDisplay(true);
    setBoxCartTrayDisplay(closeTray);
    setIsOpened(closeTray);
  };

  /**
   * Handles the outside click handle,
   * and only closes the tray for smUp screens.
   * */
  const handleOutsideClick = () => {
    if (!isSmall || isBoxCartToggleV2Enabled) {
      handleCloseTray();
    }
  };

  const { smUpTop, mdUpTop, smUpHeightOffset, mdUpHeightOffset } = getTopAndHeightInfo();

  const shouldScrollLockBeActive = checkIfScrollLockInSideTrayShouldBeActive(isOpened);

  const memberId = authData?.auth?.memberId;

  const localCartItems = localShoppingCartData?.localShoppingCart?.items?.filter(
    (item: LocalShoppingCartItem | null) =>
      item?.product?.productType?.id &&
      [
        PRODUCT_TYPE_IDS.DB_ID_PRODUCT_TYPE_WINE,
        PRODUCT_TYPE_IDS.DB_ID_PRODUCT_TYPE_SPECIAL_PACK,
        PRODUCT_TYPE_IDS.DB_ID_PRODUCT_TYPE_GIFT,
        PRODUCT_TYPE_IDS.DB_ID_PRODUCT_TYPE_SHIPPING_PRE_PAID,
      ].includes(item.product.productType.id)
  );

  // Doesn't render the box and cart tray button if user is not logged in and has no shopping cart items
  if (isCart && !isLoggedIn() && !localCartItems?.length) {
    return null;
  }

  const renderBoxTrayPriceToggleButton = () => (
    <BoxTrayPriceToggleButton
      handleChange={handleChange}
      isOpened={isOpened}
      title={isBox ? t("myBox:seeMyBox") : t("myBox:seeMyCart")}
      price={totalPriceOnToggleButton}
      yellow={isBox}
    />
  );

  const shouldDisplayNewToggleOutsideBoxTray = isBoxCartToggleV2Enabled && !isOpened && !isSmall;

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <StyledBoxTray
        isOpened={isOpened}
        smUpTop={smUpTop}
        mdUpTop={mdUpTop}
        smUpHeightOffset={smUpHeightOffset}
        mdUpHeightOffset={mdUpHeightOffset}
        isBoxCartToggleV2Enabled={isBoxCartToggleV2Enabled}
      >
        {shouldDisplayNewToggleOutsideBoxTray && (
          <BoxCartTogglesController
            variant={isSmall ? "header" : "side"}
            onBoxCartToggle={handleBoxCartToggle}
            onCloseTray={handleCloseTray}
            isBox={isBox}
            isTrayOpen={isOpened}
            boxAmountBottles={boxAmountBottles}
            boxAmountPresents={boxAmountPresents}
            cartAmountBottles={cartAmountBottles}
            cartAmountPresents={cartAmountPresents}
          />
        )}
        {!isBoxCartToggleV2Enabled && renderBoxTrayPriceToggleButton()}
        <HolderInner
          isOpened={isOpened}
          isBoxCartToggleV2Enabled={isBoxCartToggleV2Enabled}
          isHeaderShown={isHeaderShown || showHeaderBarFromChild}
        >
          <ScrollLock isActive={shouldScrollLockBeActive}>
            <TouchScrollable>
              <BoxTrayHolder isBoxCartToggleV2Enabled={isBoxCartToggleV2Enabled}>
                {isBoxCartToggleV2Enabled ? (
                  <BoxCartTogglesController
                    variant={"header"}
                    onBoxCartToggle={handleBoxCartToggle}
                    onCloseTray={handleCloseTray}
                    isBox={isBox}
                    isTrayOpen={isOpened}
                    boxAmountBottles={boxAmountBottles}
                    boxAmountPresents={boxAmountPresents}
                    cartAmountBottles={cartAmountBottles}
                    cartAmountPresents={cartAmountPresents}
                  />
                ) : (
                  <BoxTrayCartHeader
                    memberId={memberId}
                    handleChange={handleChange}
                    onBoxCartToggleLoading={handleBoxCartToggleLoading}
                    refetchMemberLayoutInfo={refetchMemberLayoutInfo}
                    isBox={isBox}
                    isCart={isCart}
                    isLoading={isBoxBeingLoaded || isCartBeingLoaded}
                  />
                )}
                {isOpened && (
                  <React.Suspense fallback={<StyledLoading />}>
                    <BoxTrayCartContent
                      isBox={isBox}
                      isBoxBeingLoaded={isBoxBeingLoaded}
                      isCartBeingLoaded={isCartBeingLoaded}
                      memberId={memberId}
                      toggleWelcomePackWarning={toggleWelcomePackWarning}
                      onCloseTray={handleCloseTray}
                      isBoxCartToggleV2Enabled={isBoxCartToggleV2Enabled}
                      isBeerSubscriptionEnabled={isBeerSubscriptionEnabled}
                    />
                  </React.Suspense>
                )}
              </BoxTrayHolder>
            </TouchScrollable>
            {isBoxCartToggleV2Enabled && (
              <BoxAndCartTrayMobileBottomSection
                onCloseTray={handleCloseTray}
                isBox={isBox}
                price={totalPriceOnToggleButton}
              />
            )}
          </ScrollLock>
          {isBoxCartToggleV2Enabled && <SmDownBackdrop isOpened={isOpened} onClick={handleOutsideClick} />}
        </HolderInner>
      </StyledBoxTray>
    </OutsideClickHandler>
  );
};

export default BoxAndCartTray;
