import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useDeepCompareEffect from "use-deep-compare-effect";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { GET_MEMBER_BASIC_INFO } from "../../../graphql/queries";
import {
  identifyUser,
  trackEvent,
  trackPageVisited,
  TRACKING_EVENT_OPTION_IS_MOBILE_APP,
} from "../../../helpers/Analytics";
import { isLoggedIn } from "../../../helpers/auth";
import {
  FETCH_POLICY_CACHE_FIRST,
  FETCH_POLICY_NETWORK_ONLY,
  I18N_NAMESPACES,
} from "../../../helpers/constants";
import { checkIfRequestIsFromMobileApp } from "../../../helpers/tools";
import { MemberBasicInfo } from "../../../types/__generated__/MemberBasicInfo";

interface PageMetaProps {
  title?: string;
  description?: string;
  image?: string;
  trackingEvents?: {
    eventName: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventData: any;
    isDuplicate?: boolean;
  }[];
  forceFetchMember?: boolean;
  canonicalUrl?: string;
}

const PageMeta = ({
  title,
  description,
  image = `${process.env.REACT_APP_BASE_URL}/social-media-preview.jpg`,
  trackingEvents = [],
  forceFetchMember = false,
  canonicalUrl = undefined,
}: PageMetaProps) => {
  const userIsLoggedIn = isLoggedIn();
  const { data: memberData } = useQuery<MemberBasicInfo>(GET_MEMBER_BASIC_INFO, {
    skip: !userIsLoggedIn,
    fetchPolicy: forceFetchMember ? FETCH_POLICY_NETWORK_ONLY : FETCH_POLICY_CACHE_FIRST,
  });
  const location = useLocation();
  const { t } = useTranslation([I18N_NAMESPACES.COMMON]);

  useEffect(() => {
    // If logged-in, identifies users on 3rd party analytics
    if (memberData?.me) {
      const subscriptionId = memberData.me.subscription?.id;
      const { firstName, lastName, email, createdDate, externalUniqueId } = memberData.me;
      identifyUser(externalUniqueId, {
        email,
        id: externalUniqueId,
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        created: createdDate,
        subscription_id: subscriptionId,
      });
    }
  }, [memberData]);

  useDeepCompareEffect(() => {
    // Sends any tracking events to 3rd party analytics
    trackingEvents?.forEach((trackingEvent) => {
      const { eventName, eventData, isDuplicate } = trackingEvent;
      trackEvent(eventName, eventData, isDuplicate);
    });
  }, [trackingEvents]);

  useEffect(() => {
    // Tracks a page visit on 3rd party analytics
    let extraData = {};
    if (location) {
      extraData = {
        [TRACKING_EVENT_OPTION_IS_MOBILE_APP]: checkIfRequestIsFromMobileApp(),
      };
    }
    trackPageVisited(title, extraData);
  }, [location]);

  // Google recommends absolute canonical URLs
  const absoluteCanonicalUrl = canonicalUrl ? new URL(canonicalUrl, window.location.origin).href : undefined;

  return (
    <Helmet>
      <title>{title ?? t("common:SEO.defaultMetaTitle")}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description ?? t("common:SEO.defaultDescription")} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description ?? t("common:SEO.defaultDescription")} />
      {absoluteCanonicalUrl && <link rel="canonical" href={absoluteCanonicalUrl} />}
    </Helmet>
  );
};

export default PageMeta;
