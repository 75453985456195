import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "react-flexbox-grid";
import { useFlag } from "@unleash/proxy-client-react";

import urlPatterns from "../../../urls";
import externalUrls from "../../../helpers/externalUrls";
import { trackEvent, trackingEvents } from "../../../helpers/Analytics";
import {
  I18N_NAMESPACES,
  UNLEASH_BEER_SUBSCRIPTION,
  UNLEASH_EARN_POINTS_V3,
  UNLEASH_NEW_PRODUCT_CATEGORIES,
  WINE_LIST_SLUGS,
} from "../../../helpers/constants";
import { isLoggedIn } from "../../../helpers/auth";
import { FooterNavbarContainer, ColumnContainer, ColumnTitle, ColumnLink, ColumnLinkExternal } from "./style";

// TODO: TRACK FAQ CLICKS
const FooterNavbar = () => {
  const isEarnPointsV3Enabled = useFlag(UNLEASH_EARN_POINTS_V3);
  const isNewProductCategoriesEnabled = useFlag(UNLEASH_NEW_PRODUCT_CATEGORIES);
  const isBeerSubscriptionEnabled = useFlag(UNLEASH_BEER_SUBSCRIPTION);
  const { t } = useTranslation([I18N_NAMESPACES.COMMON, I18N_NAMESPACES.TOP_NAVBAR]);
  const titleKey = isNewProductCategoriesEnabled ? "pastDrinks" : "pastWines";
  const isUserLoggedIn = !!isLoggedIn();
  const xsColumn = 4;

  const renderFeaturesColumn = () => (
    <ColumnContainer md xs={xsColumn}>
      <ColumnTitle>{t("topNavbar:features")}</ColumnTitle>
      <ColumnLink to={urlPatterns.MY_NEXT_WINES}>{t("topNavbar:nextBox")}</ColumnLink>
      <ColumnLink to={urlPatterns.MY_CELLAR}>{t(`common:pageMetaTitles.${titleKey}`)}</ColumnLink>
      <ColumnLink to={urlPatterns.MY_TASTE_PROFILE}>{t("topNavbar:myTaste")}</ColumnLink>
      <ColumnLink to={urlPatterns.MY_CELLAR_BOOKMARKED}>{t("topNavbar:savedLists")}</ColumnLink>
      <ColumnLink to={urlPatterns.MY_ACCOUNT}>{t("topNavbar:settings")}</ColumnLink>
      <ColumnLink to={urlPatterns.INVITE_FRIEND}>{t("topNavbar:inviteMates")}</ColumnLink>
      <ColumnLink to={urlPatterns.MY_ORDERS}>{t("topNavbar:myOrders")}</ColumnLink>
    </ColumnContainer>
  );

  const renderWinesColumn = () => (
    <ColumnContainer md xs={xsColumn}>
      <ColumnTitle>
        {isNewProductCategoriesEnabled ? t("topNavbar:winesAndMore") : t("common:wines")}
      </ColumnTitle>
      <ColumnLink to={urlPatterns.ALL_WINES}>{t("topNavbar:allWines")}</ColumnLink>
      <ColumnLink to={urlPatterns.WINE_LIST(WINE_LIST_SLUGS.NEW_RELEASES)}>
        {t("topNavbar:newReleases")}
      </ColumnLink>
      <ColumnLink to={urlPatterns.WINES}>{t("common:collections")}</ColumnLink>
      <ColumnLink to={urlPatterns.SPECIAL_PACKS}>{t("common:specialPacks")}</ColumnLink>
      <ColumnLink to={urlPatterns.GIFT_LANDING}>{t("topNavbar:gifts")}</ColumnLink>
      {isNewProductCategoriesEnabled && (
        <ColumnLink to={urlPatterns.ALL_PRODUCT_COLLECTIONS}>{t("topNavbar:more")}</ColumnLink>
      )}
    </ColumnContainer>
  );
  const renderAboutColumn = () => (
    <ColumnContainer md xs={xsColumn}>
      <ColumnTitle>{t("topNavbar:about")}</ColumnTitle>
      {isBeerSubscriptionEnabled && (
        <>
          <ColumnLink to={urlPatterns.WINE_LANDING}>{t("common:wineClub")}</ColumnLink>
          <ColumnLink to={urlPatterns.BEER_LANDING}>{t("common:beerClub")}</ColumnLink>
        </>
      )}
      <ColumnLink to={urlPatterns.ABOUT}>{t("topNavbar:who")}</ColumnLink>
      <ColumnLink to={urlPatterns.REVIEWS}>{t("topNavbar:reviews")}</ColumnLink>
      <ColumnLinkExternal href={externalUrls.BLOG} target="_blank">
        {t("topNavbar:blog")}
      </ColumnLinkExternal>
      <ColumnLinkExternal href={externalUrls.JOBS} target="_blank">
        {t("topNavbar:jobs")}
      </ColumnLinkExternal>
    </ColumnContainer>
  );

  const renderInfoColumn = () => (
    <ColumnContainer md xs={xsColumn}>
      <ColumnTitle>{t("common:info")}</ColumnTitle>
      <ColumnLinkExternal href={externalUrls.SHIPPING_INFO} target="_blank">
        {t("topNavbar:shippingInfo")}
      </ColumnLinkExternal>
      <ColumnLink to={urlPatterns.TERMS}>{t("topNavbar:termsAndPolicies")}</ColumnLink>
      <ColumnLink to={urlPatterns.CONTACT}>{t("topNavbar:contactUs")}</ColumnLink>
      <ColumnLinkExternal
        href={externalUrls.FAQ}
        onClick={() => trackEvent(trackingEvents.TRACKING_EVENT_VIEWED_FAQ, {})}
      >
        {t("topNavbar:faqs")}
      </ColumnLinkExternal>
    </ColumnContainer>
  );

  const renderRewardsColumn = () => (
    <ColumnContainer md xs={xsColumn}>
      <ColumnTitle>{t("topNavbar:rewards")}</ColumnTitle>
      {isUserLoggedIn && (
        <>
          <ColumnLink to={urlPatterns.MY_PROGRESS}>{t("topNavbar:myProgress")}</ColumnLink>
          <ColumnLink to={urlPatterns.MY_BADGES}>
            {t("topNavbar:earnPoints", {
              context: isEarnPointsV3Enabled ? "v3" : "default",
              interpolation: { escapeValue: false },
            })}
          </ColumnLink>
        </>
      )}
      {!isUserLoggedIn && (
        <ColumnLink to={urlPatterns.REWARDS_HOW_IT_WORKS}>{t("topNavbar:howRewardsWork")}</ColumnLink>
      )}
      <ColumnLink to={urlPatterns.REWARDS_STORE}>{t("topNavbar:rewardStore")}</ColumnLink>
      {isUserLoggedIn && (
        <ColumnLink to={urlPatterns.REWARDS_HOW_IT_WORKS}>{t("topNavbar:howRewardsWork")}</ColumnLink>
      )}
    </ColumnContainer>
  );

  const renderGuidesColumn = () => (
    <ColumnContainer md xs={xsColumn}>
      <ColumnTitle>{t("topNavbar:guides")}</ColumnTitle>
      <ColumnLink to={urlPatterns.GUIDE_101}>{t("topNavbar:Wine101")}</ColumnLink>
      <ColumnLink to={urlPatterns.GUIDE_GRAPE_COLOR}>{t("topNavbar:grapeVarieties")}</ColumnLink>
      <ColumnLink to={urlPatterns.GUIDE_REGION}>{t("topNavbar:regions")}</ColumnLink>
      <ColumnLink to={urlPatterns.GUIDE_TASTING}>{t("topNavbar:tasting")}</ColumnLink>
      <ColumnLink to={urlPatterns.GUIDE_MORE}>{t("topNavbar:foodWine")}</ColumnLink>
    </ColumnContainer>
  );

  return (
    <FooterNavbarContainer>
      <Row>
        {isUserLoggedIn && renderFeaturesColumn()}
        {renderWinesColumn()}
        {isUserLoggedIn && renderRewardsColumn()}
        {renderAboutColumn()}
        {renderInfoColumn()}
        {!isUserLoggedIn && renderGuidesColumn()}
        {!isUserLoggedIn && renderRewardsColumn()}
      </Row>
    </FooterNavbarContainer>
  );
};

export default FooterNavbar;
